import { css } from '@emotion/react'
import React, { Component, memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
// import PropTypes from 'prop-types';
import {
    TitleIcon,
    CardElevation,
    SliderRange,
    // FormaPago,
    ButtonDegrade,
    ModalInfo
} from '@findep/mf-landings-core'
import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';

// import { WebStorage } from '@findep/microfronts-core'

import {
    RadioGroup,
    Divider,
    FormControlLabel,
    Radio,
    Snackbar,
} from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import PagoEstimado from '../PagoEstimado'
import { StartService } from '../../services/LandingV4/start'
import { SimulatorService } from '../../services/LandingV4/simulator'
import { getPeriod } from '../../helpers/simulador'
import formatNumber from '../../helpers/formatNumber'
import { navigate } from '../../helpers/queryNavigate';
import { debounce } from 'lodash'
import FormapagoApp from '../elements/FormapagoApp'
import withProductNavigate from '../hoc/withProductNavigate'

import { getCookieValue } from '../../helpers/cookies'

const center = css`
    display: flex;
    justify-content: center;
`

const radioCss = css`
    margin-left: 3em;
`

let Start = []
let Simulator = []

class SimuladorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dedicacion: '',
            monto: '',
            plazo: '',
            period: '',
            terms: '',
            pago: '',
            flujo: '',
            open: false,
            errorService: false,
            minVar: null,
            maxVar: null,
            defaultAmountVar: null,
            incrementVar: null,
            showPayment: false,
            oEmail: false,
            dinamicFile: false
        }
        this.pagoEstimado = this.pagoEstimado.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.deleteFunction = this.deleteFunction.bind(this)

    }

    //FUNCIONES PARA EL MODAL
    openModal = () => {
        this.setState({ open: true })
    }

    onChangeModal = (e) => {
        if (e.value === "TERMINAR") {
            const navigateUrl = this.props.pageContext.errorPath;
            navigate(navigateUrl);
        } else {
            this.closeModal()
        }

    }

    closeModal = (e) => {
        this.setState({ open: false })
    }

    async componentDidMount() {

        let flujo = JSON.parse(sessionStorage.getItem('flujo'))
        let urlParams = new URLSearchParams(window.location.search)
        /**Oferta simulador */
        let df;
        if (urlParams.has('operatorEmail')) {
            this.setState({ oEmail: true })
        }
        if (urlParams.has('dinamicFile')) {
            df = urlParams.get('dinamicFile')
            if (df==='true') {
                this.setState({ dinamicFile: true })
            } else {
                this.setState({ dinamicFile: false })
            }
        }
        /**Oferta simulador */
        if (!flujo?.simulador || !flujo.creditApplicationId) {
            let broker = ''
            let tenant = this.props.pageContext.tenant || 'AEF'
            let origen

            const fbp = getCookieValue('_fbp')
            const fbc = getCookieValue('_fbc')

            if (urlParams.has('origen') || urlParams.has('broker')) {
                origen = urlParams.get('origen') || urlParams.get('broker')
                broker = origen
            }
            const source = urlParams.get('source') || urlParams.get('utm_source')
            const medium = urlParams.get('medium') || urlParams.get('utm_medium')
            const campaign = urlParams.get('campaign') || urlParams.get('utm_campaign')
            const term = urlParams.get('term') || urlParams.get('utm_term')
            const content = urlParams.get('content') || urlParams.get('utm_content')
            const operatorEmail = urlParams.get('operatorEmail')

            Start = new StartService(tenant, broker || this.props.pageContext.broker)

            Start.setUtmSource(source)
            Start.setUtmMedium(medium)
            Start.setUtmCampaign(campaign)
            Start.setUtmTerm(term)
            Start.setOperatorEmail(operatorEmail)
            Start.setUtmContent(content)
            Start.setBrowserId(fbp)
            Start.setClickId(fbc)

            try {

                const { data: startData } = await Start.send()
                //**Validacion dinamic file GPS abril 2022*/
                if (this.state.dinamicFile) {
                    const products = startData.availableProducts.filter(product => product.id === '539');
                    flujo = {
                        ...flujo,
                        creditApplicationId: startData.id,
                        simulador: {
                            availableProducts: products
                        },
                    }
                } else {
                    flujo = {
                        ...flujo,
                        creditApplicationId: startData.id,
                        simulador: {
                            availableProducts: startData.availableProducts
                        },
                    }
                }
                const { forceRedirect } = startData
                if (forceRedirect !== null && forceRedirect !== undefined) {
                    navigate(forceRedirect)
                }

            } catch (e) {
                console.error(e)
                this.setState({ errorService: true })
            }

        }

        Simulator = new SimulatorService(flujo?.creditApplicationId)
        // this.setState({ flujo, dedicacion: flujo?.simulador?.availableProducts[0].id })
        this.setState({
            flujo,
            dedicacion: flujo?.simulador?.availableProducts[0].id,
            // minVar: flujo?.simulador?.availableProducts[0].min,
            // maxVar: flujo?.simulador?.availableProducts[0].max < 0 ? flujo?.simulador?.availableProducts[0].min : flujo?.simulador?.availableProducts[0].max,
            // defaultAmountVar: flujo?.simulador?.availableProducts[0].defaultAmount,
            // incrementVar: flujo?.simulador?.availableProducts[0].increment
        })
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }



    componentDidUpdate(_, prevState) {
        // const { monto, period, terms, dedicacion } = this.state
        const { terms, dedicacion, monto, flujo, period } = this.state

        if (monto !== prevState.monto || period !== prevState.period || terms !== prevState.terms || dedicacion !== prevState.dedicacion) {
            if (monto && period && terms && dedicacion) {
                this.setState({ pago: '' })
                this.deleteFunction(dedicacion, monto, terms, period)
            }
        }
        // console.log("prevState.dedicacion=> ", prevState.dedicacion)
        // console.log("this.state.dedicacion=> ", this.state.dedicacion)
        if (dedicacion !== prevState.dedicacion) {
            const selectedProduct = flujo && flujo?.simulador && flujo?.simulador.availableProducts ?
                flujo?.simulador.availableProducts.find(producto => producto.id === dedicacion) : null

            if (selectedProduct !== null) {

                if (selectedProduct.max < 1 || selectedProduct.min < 1) {

                    this.setState({
                        minVar: selectedProduct && selectedProduct.min,
                        maxVar: selectedProduct && selectedProduct.min,
                        defaultAmountVar: selectedProduct && selectedProduct.min,
                        incrementVar: selectedProduct && selectedProduct.increment,
                        open: true
                    })
                    // console.log("maximos negativos Error", selectedProduct.min, selectedProduct.max, this.state.defaultAmountVar)

                } else {

                    if (selectedProduct.defaultAmount >= selectedProduct.min && selectedProduct.defaultAmount <= selectedProduct.max) {
                        // console.log("entro sin problemas")
                        this.setState({
                            minVar: selectedProduct && selectedProduct.min,
                            maxVar: selectedProduct && selectedProduct.max,
                            defaultAmountVar: selectedProduct && selectedProduct.defaultAmount,
                            incrementVar: selectedProduct && selectedProduct.increment
                        })
                    } else {
                        this.setState({
                            minVar: selectedProduct && selectedProduct.min,
                            maxVar: selectedProduct && selectedProduct.max,
                            defaultAmountVar: selectedProduct && selectedProduct.min,
                            incrementVar: selectedProduct && selectedProduct.increment,
                            open: true
                        })
                        // console.log("defaultamount fuera de rango")
                    }


                }
            }

            // console.log("selectedProduct=> ", selectedProduct)
        }

        /**Oferta simulador */
        // console.log("oEmail", this.state.oEmail, prevState.oEmail)
        if (this.state.dinamicFile !== prevState.dinamicFile) {
            this.setState({oEmail: this.state.dinamicFile})
        }
        /**Oferta simulador */

    }

    deleteFunction = debounce(function (dedicacion, monto, terms, period) {
        this.pagoEstimado(dedicacion, monto, terms, period)
    }, 250);

    async pagoEstimado(productId, amount, terms, period) {
        this.setState({ loading: true })
        Simulator.setProductId(productId)
        Simulator.setRequestedAmount(amount)
        Simulator.setTerms(Number(terms))
        Simulator.setPeriod(getPeriod(period))
        try {
            if (productId === 'FTPE' || productId === "539") {
                this.setState({ showPayment: true })
            } else {
                this.setState({ showPayment: false })
            }
            const { data: dataSimulator } = await Simulator.send()

            sessionStorage.setItem('cotizador', JSON.stringify(
                {
                    renta: dataSimulator.estimatedPayment,
                    cat: dataSimulator.cat,
                    monto: amount
                }
            ))
            this.setState({ pago: dataSimulator.estimatedPayment })
            console.log('entro con...', productId, ' ', amount, ' ', ' ', terms, ' ', period, 'responde', dataSimulator.estimatedPayment);
        } catch (e) {
            console.error(e)
            this.setState({ errorService: true })
            this.goErrorPath()
        } finally {
            this.setState({ loading: false })
        }
    }

    goErrorPath() { setTimeout(navigate(this.props.pageContext.errorPath), 250) }


    handleChange(element, value) {
        this.setState({ [element]: value.value })
    }

    render() {
        const { dedicacion, monto, pago, plazo, flujo, period, showPayment, oEmail, dinamicFile } = this.state

        const { company, journey } = this.props.pageContext

        const product = dedicacion, fallbackUrl = this.props.pageContext?.next?.path

        const isAfi = company?.toUpperCase() === 'AFI'

        // const showPayment = true

        // const selectedProduct = flujo && flujo?.simulador && flujo?.simulador.availableProducts ?
        //     flujo?.simulador.availableProducts.find(producto => producto.id === dedicacion) : null

        const datosFP = {
            formaPago: plazo,
            tituloSelect: "¿En qué plazo lo quieres pagar?",
            colorEmpresa: 'primary'
        }

        let isProductAvailable = flujo?.simulador?.availableProducts?.filter(element => element.displayName)
        if (dinamicFile) {
            isProductAvailable = isProductAvailable?.length >= 1
        } else {
            isProductAvailable = isProductAvailable?.length > 1
        }

        const renderSimulador = Boolean(flujo?.simulador?.availableProducts)

        const renderProducts = () => {
            return flujo && flujo?.simulador && flujo?.simulador.availableProducts && (
                <RadioGroup id="simulador-producto-group" css={radioCss} value={dedicacion} aria-label="dedicacion" name="dedicacion" onChange={(event) => this.handleChange('dedicacion', { value: event.target.value })}>
                    {
                        flujo?.simulador.availableProducts.map(producto => (
                            producto.displayName && <FormControlLabel key={producto.id} value={producto.id} control={<Radio id={`simulador-producto-${producto.displayName}-input`} />} label={producto.displayName} />
                        ))
                    }
                </RadioGroup>
            )

        }

        return (
            <>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
                {renderSimulador ? (
                    <CardElevation>
                        {
                            isProductAvailable && (
                                <>
                                    <TitleIcon icon={WorkIcon} title="¿A qué te dedicas?" />
                                    {renderProducts()}
                                    <Divider />
                                    <br />
                                </>
                            )
                        }

                        <TitleIcon disabled={!dedicacion && isProductAvailable} icon={MonetizationOnSharpIcon} title="¿Cuánto necesitas?" />
                        <SliderRange
                            min={this.state.minVar}
                            max={this.state.maxVar}
                            step={this.state.incrementVar}
                            disabled={!dedicacion && isProductAvailable}
                            onChange={(event) => this.handleChange('monto', { value: event.mount })}
                            id="simulador-monto"
                            defaultValue={this.state.defaultAmountVar}
                            alternalText={isAfi && `${formatNumber(this.state.monto, 3, ',', '$', 0, '')} dólares`}
                        />
                        <br />
                        <Divider />
                        <br />
                        <TitleIcon disabled={!dedicacion || !monto} icon={CalendarTodayIcon} title="Elige una forma de pago" />
                        <FormapagoApp handleChange={this.handleChange} company={company} dedicacion={dedicacion} monto={monto} configuration={datosFP} />
                        {pago && showPayment && oEmail && <><Divider /><br /><TitleIcon disabled={!dedicacion || !monto || !plazo} icon={AttachMoneyIcon} title={<div>Pago estimado<sup>*</sup></div>} /></>}
                        <div>
                            {pago && showPayment && oEmail && <PagoEstimado id="simulador-pago" text={`${formatNumber(pago, 3, ',', '$', 0, '')} ${period}`} />}
                        </div>
                        <br />
                        <div css={center}>
                            <ButtonDegrade
                                id="simulador-continuar"
                                icon={ArrowForwardIcon}
                                disabled={!dedicacion || !monto || !plazo || !pago}
                                textButton='Continuar con solicitud'
                                onClick={
                                    () => this.props.productNavigate({
                                        company,
                                        product,
                                        journey,
                                        fallbackUrl,
                                        navigate
                                    }
                                    )
                                }
                            />
                        </div>
                    </CardElevation>
                ) : (
                    <Skeleton variant="rect" height={350} />
                )}
                <ModalInfo
                    // onClose={this.closeModal}
                    autoClose={false}
                    icon={CancelIcon}
                    title="Lo sentimos"
                    color="warning"
                    open={this.state.open}
                    body={<div><p>No tienes la antigüedad mínima para acceder a un crédito.  Gracias.</p></div>}
                    // btnLeft="INTERTAR DE NUEVO"
                    btnRight="TERMINAR"
                    onChange={(data) => this.onChangeModal(data)}
                />
            </>
        );
    }
}



export default memo(withProductNavigate(SimuladorCard));